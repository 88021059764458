import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import log from "loglevel";
import { useContext, useState } from "react";
import { getIam } from "../libs/utils";
import AppContext from "./AppContext";
import SearchableTable from "./SearchableTable";
import TooltipCell from "./TooltipCell";

const getDomains = async (ctx: any, setDomains: Function, domain?: string) => {
  const resp = await getIam("/api/admin/domains", {
    domain,
  });
  if (resp.error) {
    log.error("getDomains::", resp);
    ctx.setAlert(resp.error, "error");
  } else {
    if (resp.length === 0) {
      ctx.setAlert("No entries found", "info");
    }
    setDomains(resp);
  }
};

const columns = [
  {
    field: "id",
    headerName: "Domain Id",
    flex: 1,
    valueParser: ({ value }: any) => value,
    renderCell: ({ value }: any) => <TooltipCell value={value} />,
  },
  {
    field: "domain",
    headerName: "Domain",
    flex: 1,
    valueParser: ({ value }: any) => value,
    renderCell: ({ value }: any) => <TooltipCell value={value} />,
  },
  {
    field: "isSAMLEnforced",
    headerName: "SAML Enforced",
    flex: 1,
    type: "boolean",
    valueFormatter: ({ value }: any) => Boolean(value).toString(),
    renderCell: ({ value }: any) => (
      <TooltipCell value={Boolean(value).toString()} />
    ),
  },
  {
    field: "isSAMLIDPInitiated",
    headerName: "IDP SAML",
    flex: 1,
    type: "boolean",
    valueFormatter: ({ value }: any) => Boolean(value).toString(),
    renderCell: ({ value }: any) => (
      <TooltipCell value={Boolean(value).toString()} />
    ),
  },
  {
    field: "isSAMLSPInitiated",
    headerName: "SP SAML",
    flex: 1,
    type: "boolean",
    valueFormatter: ({ value }: any) => Boolean(value).toString(),
    renderCell: ({ value }: any) => (
      <TooltipCell value={Boolean(value).toString()} />
    ),
  },
  {
    field: "oninboxCentralManagement",
    headerName: "OnINBOX Central Management",
    flex: 1,
    valueParser: ({ value }: any) => value,
    renderCell: ({ value }: any) => <TooltipCell value={value} />,
  },
];

const AdminDomainsExplorer = () => {
  const ctx = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState("");
  const [domains, setDomains] = useState([]);

  return (
    <div>
      <form
        style={{ display: "flex", flexFlow: "wrap" }}
        onSubmit={async (ev) => {
          setLoading(true);
          ev.preventDefault();
          await getDomains(ctx, setDomains, domain);
          setLoading(false);
        }}
      >
        <TextField
          id="email"
          label="domain (partial match)"
          style={{ minWidth: 200, margin: "5px 0px 0px 5px" }}
          variant="outlined"
          onChange={(ev) => setDomain(ev.target.value)}
        />
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          style={{ margin: "5px 0px 0px 5px" }}
        >
          Find
        </LoadingButton>
      </form>
      <SearchableTable
        tableId="domains-explorer"
        columns={columns}
        rows={domains}
        style={{ marginTop: 20 }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
      />
    </div>
  );
};

export default AdminDomainsExplorer;

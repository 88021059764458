import log from "loglevel";
import { useQuery } from "react-query";
import { IAM_URL } from "../libs/constants";
import { logout } from "../libs/utils";
// import TEST_SIFTS from "../stubs/sifts.json";

const useGetSifts = (ready: boolean) => {
  return useQuery(
    "sifts",
    () =>
      fetch(IAM_URL + "/api/sifts", { credentials: "include" })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 401) {
            log.error("useGetSifts::unauthorized, logging out...");
            logout();
          } else {
            throw new Error(
              `Could not retrieve sifts information. Status code: ${res.status}. Status message: ${res.statusText}`
            );
          }
        })
        .then((body) => {
          log.debug("useGetSifts::got:", body);

          // NOTE: Stub to return a static list of sifts
          // log.error("useGetSifts:: *** RUNNING WITH TEST SIFTS ***");
          // body.sifts = TEST_SIFTS;

          const sifts: any = {};
          for (const s of body.sifts) {
            if (!sifts[s.guid]) {
              sifts[s.guid] = [];
            }
            sifts[s.guid].push(s);
          }
          return sifts;
        }),
    {
      retry: (failureCount, error) => {
        if ((error as Response).status === 401 || failureCount > 3) {
          log.error("useGetSifts::retry::error:", failureCount, error);
          return false;
        }
        log.debug("useGetSifts::retry:", failureCount, error);
        return true;
      },
      enabled: ready,
      staleTime: 5 * 60 * 1000, // Refetch every 5 minutes
    }
  );
};

export default useGetSifts;

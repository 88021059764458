import log from "loglevel";
import { useContext, useEffect, useRef } from "react";
import SiftRoundabout from "../libs/sift-roundabout";
import AppContext from "./AppContext";
import { PULSE_GUID } from "../libs/constants";

const Sift = ({
  user,
  installed,
  siftJwt,
}: {
  user: any;
  installed: any;
  siftJwt: any;
}) => {
  const syncWorkerRef = useRef<any>();
  const siftControllerWorkerRef = useRef<any>();
  const siftViewRef = useRef<any>();
  const ctx = useContext(AppContext);
  const { rightDrawerOpen } = ctx;
  useEffect(() => {
    log.debug("Sift::useEffect", siftJwt);
    const siftInfo = {
      id: installed.id,
      guid: installed.guid,
      siftJSON: installed.sift.attributes.siftJSON,
      syncJwt: siftJwt.token,
      siftJwt: siftJwt.token,
      web_url: installed.sift.attributes.web_url,
    };
    const doesUserHavePulse = user?.sifts.some(
      (s: any) => s.guid === PULSE_GUID
    );

    const siftPermissionsConfig = {
      roles: installed.users.find((u: any) => u.id === user.id)?.roles || [
        "superreader",
        "customersuccess",
      ], // Fallback for CS access
      currentUser: {
        id: user.id,
        email: user.email, // NOTE: fix OnDMARC to remove this duplication
        profile: {
          email: user.email,
          fullName: user.profile?.name,
        },
        isPulseEnabled: doesUserHavePulse,
      },
      data: {
        users: installed.users,
        groups: installed.groups,
      },
      config: installed.sift.attributes.siftJSON.permissions || {},
    };
    log.debug("Sift::useEffect::siftPermissionsConfig", siftPermissionsConfig);
    const roundabout = new SiftRoundabout(
      ctx,
      siftInfo,
      syncWorkerRef,
      siftControllerWorkerRef,
      siftViewRef,
      siftPermissionsConfig
    );
    // Let the traffic commence
    roundabout.letsGo();

    return () => {
      roundabout.terminate();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, installed, siftJwt]);

  // NOTE: in the past we used to encapsulate this iframe inside of another iframe so that
  // when it posted message to its parent window we knew what sift was sending it and could
  // direct it to the correct view/controller/etc... this was useful for tiling sifts
  // This is no longer supported anyway as sifts are now allowed to manipulate the browser URL
  // If tiling is ever required again, we'd need to modify the messages exchanged between
  // view/controller/client to have a common ID to route them correctly

  const w = `100vw`;
  const rightDrawerWidth = !rightDrawerOpen ? "" : " - 67px";
  const fullWidth = `calc(${w}${rightDrawerWidth})`;
  return (
    <div
      style={{
        height: "100vh",
        width: `calc(100%${rightDrawerWidth})`,
      }}
    >
      <iframe
        id={`${installed.id}`}
        ref={siftViewRef}
        style={{
          display: "block",
          border: "none",
          width: fullWidth,
        }}
        src=""
        title="sift"
        referrerPolicy="no-referrer-when-downgrade"
        sandbox="allow-downloads allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        allow="fullscreen clipboard-read; clipboard-write"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default Sift;

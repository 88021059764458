import log from "loglevel";
import { useContext, useEffect, useRef } from "react";
import SiftRoundabout from "../libs/sift-roundabout";
import AppContext from "./AppContext";
import {
  CLASS_RADAR_CHAT_BOT,
  PULSE_GUID,
  RADAR_SIZE_FULL_SCREEN,
} from "../libs/constants";

const RadarIframe = ({
  user,
  sifts,
  siftJwts,
}: {
  user: any;
  sifts: any;
  siftJwts: any;
}) => {
  const syncWorkerRef = useRef<any>();
  const siftControllerWorkerRef = useRef<any>();
  const siftViewRef = useRef<any>();
  const ctx = useContext(AppContext);
  const {
    radarParams: { input, prompt, radarOpen, product },
    radarSize: { sizeMode },
    rightDrawerOpen,
  } = ctx;
  const pulseSifts = sifts[PULSE_GUID];
  const pulseSift = pulseSifts[0];
  const siftJwt = siftJwts.find((s: any) => s.id === pulseSift.id);

  useEffect(() => {
    console.log("radar params", input, prompt, radarOpen, product);
    log.debug("Sift::useEffect", siftJwt);
    const siftInfo = {
      id: pulseSift.id,
      guid: pulseSift.guid,
      siftJSON: pulseSift.sift.attributes.siftJSON,
      syncJwt: siftJwt.token,
      siftJwt: siftJwt.token,
      web_url: pulseSift.sift.attributes.web_url,
    };
    const doesUserHavePulse = user?.sifts.some(
      (s: any) => s.guid === PULSE_GUID
    );

    const siftPermissionsConfig = {
      roles: pulseSift.users.find((u: any) => u.id === user.id)?.roles || [
        "superreader",
        "customersuccess",
      ], // Fallback for CS access
      currentUser: {
        id: user.id,
        email: user.email, // NOTE: fix OnDMARC to remove this duplication
        profile: {
          email: user.email,
          fullName: user.profile?.name,
        },
        isPulseEnabled: doesUserHavePulse,
      },
      data: {
        users: pulseSift.users,
        groups: pulseSift.groups,
      },
      config: pulseSift.sift.attributes.siftJSON.permissions || {},
    };
    log.debug("Sift::useEffect::siftPermissionsConfig", siftPermissionsConfig);
    const viewParams = {
      sizeClass: RADAR_SIZE_FULL_SCREEN,
      type: CLASS_RADAR_CHAT_BOT,
      ...(Boolean(input && prompt) && {
        radarParams: { input, prompt, product },
      }),
    };
    const clientMessageHandlerOptions = { disableSyncHistory: true };
    const roundabout = new SiftRoundabout(
      ctx,
      siftInfo,
      syncWorkerRef,
      siftControllerWorkerRef,
      siftViewRef,
      siftPermissionsConfig,
      viewParams,
      clientMessageHandlerOptions,
      "radar-chat-bot-id"
    );
    // Let the traffic commence
    roundabout.letsGo();
    return () => {
      roundabout.terminate();
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, pulseSift, siftJwt, input, prompt, product]);

  if (!pulseSifts) {
    return <></>;
  }

  // NOTE: in the past we used to encapsulate this iframe inside of another iframe so that
  // when it posted message to its parent window we knew what sift was sending it and could
  // direct it to the correct view/controller/etc... this was useful for tiling sifts
  // This is no longer supported anyway as sifts are now allowed to manipulate the browser URL
  // If tiling is ever required again, we'd need to modify the messages exchanged between
  // view/controller/client to have a common ID to route them correctly
  return (
    <div
      style={{
        height: "100%",
        position: "fixed",
        top: 0,
        right: `${!rightDrawerOpen ? "-67" : "0"}px`,
        width: sizeMode === "THEATRE" ? `calc(100vw - 254px)` : "696px",
        marginLeft: "auto",
        zIndex: 1000,
      }}
    >
      <iframe
        id={`${pulseSift.id}`}
        ref={siftViewRef}
        style={{
          display: "block",
          border: "none",
        }}
        src=""
        title="sift"
        referrerPolicy="no-referrer-when-downgrade"
        sandbox="allow-downloads allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        allow="fullscreen clipboard-read; clipboard-write"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default RadarIframe;
